<!--
  Provides a detail view of a Type within a Token Contract (Collection) in the distro system.
  Also displays a list of all Tokens of that type.
-->
<template>
  <div class="item-view">
    <ItemHeader
      :data="typeData"
      backLabel="Collection"
      @back="$router.push(`/distro/collections/${$route.params.collection}`)"
    />
    <div class="bottom">
      <TokenTable
        v-if="claims"
        :data="claims"
        :additionalHeaders="headers"
        hideDefaultHeaders
        hideActionButton
      />
    </div>
  </div>
</template>

<script>
import { doc, query, getDoc, getDocs, where } from 'firebase/firestore'
export default {
  async created () {
    const res = await getDoc(
      doc(
        this.$fb.db,
        'token-contracts',
        this.$route.params.collection,
        'types',
        this.$route.params.type
      )
    )
    const typeData = res.data()
    typeData.image = this.tokenImagePath(
      this.$route.params.collection,
      this.$route.params.type,
      200,
      true
    )
    this.typeData = typeData
    const claims = await getDocs(query(this.$fb.claimsCollection, where('type', '==', this.$route.params.type)))
    claims.forEach((claim) => {
      this.claims.push(claim.data())
    })
  },
  data () {
    return {
      claims: [],
      typeData: {},
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Claimed',
          value: 'claimedTimestamp'
        }
      ]
    }
  }
}
</script>

<style>

</style>
